import axios from "axios"
import { userAuth } from "@/state/user"

axios.interceptors.request.use(
  (config) => {
    const userStore = userAuth();
    if (config.url.includes("minio.findnear.vn")) {
      return config;
    } else if (config.url.includes("collaboratorv3.findnear.vn") || config.url.includes("dcollaborator.findnear.vn")) {
      const token = userStore.tokenFinxu;
      const tokenUser = userStore.token;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['usertoken'] = `Bearer ${tokenUser}`;
      }
      return config;
    } else {
      const token = userStore.token;
      const tokenFinxu = userStore.tokenFinxu;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['findcointoken'] = `Bearer ${tokenFinxu}`;
      }
      return config;
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)
