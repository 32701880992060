import { createApp , markRaw} from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import './plugin/axios'
import globalMixins from './hookandmixin/globalMixin';
import BootstrapVueNext from 'bootstrap-vue-next';

import vClickOutside from "click-outside-vue3";
import Maska from 'maska';''

import VueFeather from 'vue-feather';

import 'vue3-toastify/dist/index.css';

import '@/assets/scss/config/corporate/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import '@/assets/scss/custom.scss';


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(router)
    })

pinia.use(piniaPluginPersistedstate)

createApp(App)
    .mixin(globalMixins)
    .use(pinia)
    .use(router)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside).mount('#app');