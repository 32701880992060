/* eslint-disable */
import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import { userAuth } from '@/state/user'; 

const theUserHavePermission = (inputRoute) => {
console.log(inputRoute);

  const theUser = userAuth();

  const permissionsData = theUser?.userRoleGroupData?.permissions;
  localStorage.setItem('permissions', JSON.stringify({...permissionsData, role: theUser?.user?.roleTitle}));
  console.log(permissionsData);
    if (theUser?.user?.roleTitle == 'admin') {
      return true
    } else if (!permissionsData) {
      return false
    } else {
      const routePermissions = {
        'users': permissionsData?.user?.list_user?.get,
        'userdetail': permissionsData?.user?.list_user?.get,
        'collabs': permissionsData?.user?.list_ctv?.get,
        'taxilist': permissionsData?.taxi?.list_taxi?.get,
        'taxidetail': permissionsData?.taxi?.list_taxi?.get,
        'findxu': permissionsData?.findxu?.list_findxu?.get,
        'store': permissionsData?.store?.list_store?.get,
        'storedetail': permissionsData?.store?.list_store?.get,
        'orders': permissionsData?.store?.list_store?.get,
        'orderdetail': permissionsData?.store?.list_store?.get,
        'banner': permissionsData?.banner?.list_banner?.get,
        'news': permissionsData?.news?.list_news?.get,
        'newscreate': permissionsData?.news?.list_news?.post,
        'newsdetail': permissionsData?.news?.list_news?.get,
        'ads':permissionsData?.ads?.list_ads?.get,
        'adsdetail':permissionsData?.ads?.list_ads?.get,
        'adscreate':permissionsData?.ads?.list_ads?.post,
        'adspackages':permissionsData?.ads?.list_ads_package?.get,
        'adseven':permissionsData?.ads?.list_ads_event?.get,
        'posts': permissionsData?.news?.list_news?.get,
        'schedulePost': permissionsData?.news?.list_news?.get,
        'violatingPost': permissionsData?.news?.list_news?.get,
        'refunds': permissionsData?.store?.list_store?.get,
        'taxiconfig': permissionsData?.option?.list_option?.get,
        'configdrivercancelreason': permissionsData?.option?.list_option?.get,
        'configstorefields': permissionsData?.option?.list_option?.get,
        'configstoreservices': permissionsData?.option?.list_option?.get,
        'configsocialviolationreason': permissionsData?.option?.list_option?.get,
        'configareaarea': permissionsData?.option?.list_option?.get,
        'credits': permissionsData?.user?.list_loan?.get,
      };
      if (routePermissions[inputRoute] == 1) {
        return true;
      } else {
        return false
      }
    }
  }

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
router.beforeEach(async (routeTo, routeFrom, next) => {
  // console.log(routeTo);
  const theUser = userAuth();
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (routeTo.name == 'login' && theUser.isAuthenticated) {
    console.log('login route');
    next({
      name: 'dashboard',
    });
  } else if (!authRequired) {
    return next();
  } else {
    if (theUser.isAuthenticated) {
      // Check if the expiration time has passed
      const now = new Date();
      if (theUser.expirationTime && now > new Date(theUser.expirationTime)) {
        // Logout and redirect to the login page
        theUser.logout();
        next({
          name: 'login',
        });
      } else {
        // next();
        if (theUserHavePermission(routeTo.name)) {
          next();
        }  else if (routeTo.fullPath == '/dashboard') {
          next();
        } else {
          next({
            name: 'dashboard',
          });
        }

      }
    } else {
      next({
        name: 'login',
      });
    }
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;
