import { defineStore } from "pinia";
import axios from "axios";

export const userAuth = defineStore({
  id: "auth",
  state: () => ({
    token: "",
    tokenFinxu: "",
    user: null,
    expirationTime: null,
    userRoleGroupData: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    isAdmin: (state) => {
      // Check if user exists and has the roleTitle 'admin'
      if (state.user?.roleTitle === "admin") {
        return true;
      }
      return false;
    },
    userHaveRight: (state) => (targetKey, inputType) => {
      if (state.user?.roleTitle === "admin") {
        return true;
      } else {
        const userPermissions = state.userRoleGroupData?.permissions;
        // console.log(userPermissions);
        if (userPermissions) {
          if (userPermissions && targetKey in userPermissions) {
            return true;
          }

          if (userPermissions[targetKey]) {
            return true;
          } else {
            for (let key in userPermissions) {
              if (userPermissions[key][targetKey]) {
                const _permis = userPermissions[key][targetKey][inputType];
                if (_permis == 1) {
                  return true;
                }
              }
            }
          }
          // return true;
          return false;
        } else {
          // return true;
          return false;
        }
      }
    },
  },
  actions: {
    login(token, findxuToken, userInfo) {
      // Set the token in the store's state
      this.token = token;
      this.tokenFinxu = findxuToken;
      // Set the user info in the store's state
      this.user = userInfo;

      const now = new Date();
      const endOfDay = new Date(now);
      endOfDay.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
      this.expirationTime = endOfDay;
      // localStorage.setItem('token', token);
      // localStorage.setItem('userInfo', JSON.stringify(userInfo));
      this.getUserRoleGroup();
    },
    logout() {
      // Clear token and user info when logging out
      this.token = "";
      this.user = null;
      this.expirationTime = null;
      this.userRoleGroupData = null;
      // localStorage.removeItem('token');
      // localStorage.removeItem('userInfo');
      // push to logout '/logout'
      // clear localstorage
      localStorage.clear();
      this.router.push("/logout");
    },
    getUserRoleGroup() {
      const apiDomain = process.env.VUE_APP_API_DOMAIN_1;

      let thegroup = this.user.groupPermissionId;

      const apiUrl = `${apiDomain}/manage/group-permission/${thegroup}`;
      // 84333123459
      axios
        .get(apiUrl)
        .then((response) => {
          // Handle the response data here
          this.userRoleGroupData = response.data.data;

          if (
            this.user.roleTitle !== "admin" &&
            this.userRoleGroupData?.permissions?.user?.list_loan?.get &&
            !this.userRoleGroupData?.permissions?.user?.list_user?.get
          ) {
            this.tokenFinxu = null;
            this.router.push("/user/credits");
          } else {
            this.router.push("/dashboard");
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("Error:", error);
        });
    },
  },
  persist: true,
});
